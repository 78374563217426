@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind extend; */
@layer base {
  html {
    font-family: 'Open Sans', sans-serif;
    background: #ffffff;
  }

  strong {
    @apply font-medium;
  }
  /**
  * Home clients table 
  */

  .col {
    @apply border-r border-gray-300 p-8 flex items-center justify-center;
  }
  .col:last-child {
    @apply border-r-0;
  }
  .image-container div {
    position: relative !important;
  }

  @layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    /* Show scrollbar */
    .scrollbar::-webkit-scrollbar {
      display: block;
    }
    .scrollbar {
      -ms-overflow-style: auto; /* IE and Edge */
      scrollbar-width: auto; /* Firefox */
    }
  }

  /* nav.navbar ul li a {
    @apply justify-center text-black hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300;
  } */
  nav.navbar ul li a {
    @apply justify-center text-black hover:text-[#0050D5] hover:border-[#0050D5] dark:hover:text-[#0050D5];
  }
  nav.navbar ul li a.active {
    @apply text-[#0050D5] border-[#0050D5] dark:text-[#0050D5] dark:border-[#0050D5];
  }
  nav.pagination ul li .pagination-link {
    color: #7e7e7e;
    /* width: 50px;
    height: 50px; */
    text-align: center;
    cursor: pointer;
    /* @apply block font-bold px-4 py-3 !rounded-md text-primary bg-white border border-primary hover:bg-blue-100 hover:text-stickyPrimary; */
  }
  nav.pagination ul li .pagination-link.active {
    @apply border-[#0050D5] text-[#fff] bg-[#0050D5] p-[7px_12px] rounded-[100%] font-bold hover:bg-stickyPrimary hover:text-blue-100;
  }
  nav.pagination ul li button.pagination-link {
    /* width: 50px;
    height: 50px; */
    text-align: center;
    background: #eeeeee;
    border-radius: 30px;
    /* @apply block font-bold px-4 py-3 !rounded-md text-primary bg-white border border-primary hover:bg-blue-100 hover:text-stickyPrimary; */
  }
  nav.pagination ul li button.pagination-link.active {
    @apply border-transparent text-white bg-primary hover:bg-stickyPrimary hover:text-blue-100;
  }
  .image-container > .image {
    object-fit: contain;
    position: relative !important;
    height: unset !important;
  }
  section.blog-page a {
    @apply text-primary underline;
  }
  section.blog-page > div {
    @apply mb-8;
  }
  section.blog-page > div > h2 {
    @apply text-secondary mb-3;
  }
  section.blog-page > div > div > h3 {
    @apply text-secondary;
  }
  section.blog-page > div > div {
    @apply mb-4;
  }
  section.blog-page > div > p:not(:last-child) {
    @apply mb-2;
  }
  .case-study-list > li {
    @apply ml-3 pl-2 mb-1;
  }
  .case-study-list > li::marker {
    content: '●';
    @apply text-primary text-xl;
  }
  select,
  textarea {
    color: theme('colors.dark');
    @apply bg-gray-200 p-3 border border-gray-300 rounded-sm w-full text-sm focus:border-primary focus:ring-primary;
  }
  input[type='checkbox'] {
    @apply w-4 h-4 text-primary bg-lighterGray rounded border-lightGray focus:ring-primary focus:ring-2;
  }
  input[type='range'] {
    @apply bg-company;
  }
  .row {
    @apply border-b-2 border-gray-500;
  }
  .row:last-child {
    @apply border-b-0;
  }
  .col {
    @apply border-r-2 border-gray-500;
  }
  .card {
    @apply bg-white border border-gray-200 rounded-md;
  }
  input {
    @apply focus:outline-primary;
  }
  input {
    @apply placeholder:text-dark;
  }
  textarea {
    @apply resize-none;
    @apply placeholder:text-dark;
  }
  button {
    @apply !rounded p-2 pt-3 ease-out duration-300;
  }
  div.w-fit > button {
    @apply p-0 rounded-none;
  }
  input,
  button {
    @apply disabled:pointer-events-none disabled:cursor-auto disabled:opacity-50;
  }
  .member-card {
    @apply transition-colors duration-300 ease-in-out;
  }
  .member-card:hover {
    @apply bg-primary;
  }
  .member-card:hover * {
    @apply text-white;
  }
  .services-card:hover .services-img {
    display: none;
    /* @apply absolute text-white inset-0; */
    /* py-6; */
  }
  .services-card:hover .services-text.plain {
    @apply bg-primary;
  }

  .services-card:hover .services-text button {
    @apply block mx-auto;
  }
  textarea {
    @apply resize-none;
    @apply placeholder:text-dark;
  }

  .link {
    @apply hover:text-primary focus:text-primary ease-in duration-150;
  }
  .link-active {
    @apply text-primary focus:text-primary ease-in duration-150;
  }
  .lca-table-td {
    @apply p-[10px] font-sans font-[400] text-[#353535] text-[16px];
  }

  @keyframes rpt-slideUpDown {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes rpt-slideUpDown-out {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
}

@font-face {
  font-family: 'DM Sans';
  src: url('/fonts/dm-sans/DM-Sans-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('/fonts/dm-sans/DM-Sans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('/fonts/dm-sans/DM-Sans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('/fonts/dm-sans/DM-Sans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('/fonts/dm-sans/DM-Sans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
/* globals.css */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

.marquee-text > div {
  display: inline-block;
}

.phoneNumberNobor.react-tel-input .form-control {
  border: 0;
  height: auto;
  background-color: transparent;
}

.tec-info .slick-initialized .slick-slide > div {
  margin-bottom: 20px !important;
}

.react-tel-input .form-control {
  border-radius: 5px 5px 5px 5px !important;
  height: 48px;
  border: 1px solid #dadada;
}
