:root {
  --font-roboto: 'Roboto', sans-serif;
}

.roboto {
  font-family: var(--font-roboto);
}
.dm_sans,
.dm_sans * {
  font-family: 'DM Sans';
}
.title_hd strong {
  font-weight: 800;
}
.stickthis {
  position: sticky;
  top: 120px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.scrollwidth::-webkit-scrollbar {
  width: 0px;
}

section.blog-page > div > div.humb {
  margin-bottom: 0px;
}

.min-inline-size {
  min-inline-size: fit-content;
}

.line-clamp-post {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.true .faq-question:before {
  content: '-' !important;
  font-size: 24px;
  top: 10px;
}

.open\=true.true {
  background: #f7faff;
}
.backgroundsize {
  background-size: 100%;
}

.bg-size-100 {
  background-size: 100%;
}
.bg-size-80 {
  background-size: 80%;
}
.bg-size-60 {
  background-size: 60%;
}
.bg-size-40 {
  background-size: 40%;
}

.slick-track {
  margin: 10px 0px;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 0px 20px;
}
/* .slick-slide img {
  margin: auto;
} */
.slick-initialized .slick-slide > div {
  margin-bottom: 20px !important;
}

img.Ellipse144 {
  position: absolute;
  top: -20%;
  right: 0;
  z-index: -1;
}
img.Ellipse143 {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(
      calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14))
    );
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(
      calc(-100% - calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14))
    );
  }
}

/* Parent wrapper */
.wrapper {
  display: flex;
  flex-direction: column;
  gap: calc(clamp(10rem, 1rem + 40vmin, 30rem) / 14);
  margin: auto;
  max-width: 100vw;
}

.testimonial-bg-block .slick-initialized .slick-slide {
  display: block;
  margin-top: 60px;
}
.transation-custom {
  transition: 0.5s ease;
}

.gradient_text {
  background: -webkit-linear-gradient(
    180deg,
    rgba(207, 202, 255, 0.82) 0%,
    rgba(225, 222, 255, 0.2378) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.active-blog {
  color: #0050d5;
}

.open\=false .faq-answer,
.open\=false .drop_answer {
  padding: 0px;
  height: 0px;
  overflow: hidden;
}

.open\=true .faq-answer,
.open\=true .drop_answer {
  height: auto;
  overflow: hidden;
}

.new_blog_detail a {
  color: rgb(5, 99, 193);
}

.fullimage img {
  width: 100%;
}

.footergptwlogo > div:first-child img {
  padding: 5px 8px 5px 0px;
}

.getTouchModalPhone.react-tel-input .form-control {
  height: auto;
  padding-top: 0.75rem;
  padding-bottom: 0.7rem;
  border: 1px solid #dadada;
}

.getTouchModalPhone.noRadius .form-control {
  border-radius: 0px !important;
}

.phoneNumberNobor.react-tel-input .flag-dropdown {
  background: transparent;
  border: 0px !important;
}

#innerScroll {
  scrollbar-width: none; /* for Firefox */
  max-height: 70vh;
}
#innerScroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.anchorInPage a {
  color: #15c;
  font-weight: 500;
}

code.language-plaintext,
code.language-c,
code.language-cs,
code.language-cpp,
code.language-css,
code.language-diff,
code.language-html,
code.language-java,
code.language-javascript,
code.language-ruby,
code.language-typescript,
code.language-xml,
code.language-php,
code.language-python {
  width: 100%;
  background: #ddd;
  display: block;
  padding: 15px;
  border-radius: 5px;
}

main.\/blog\/pods-in-kubernetes\/
  .blog_detail_page
  table
  tbody
  tr:first-child
  td
  p
  span,
main.\/blog\/pods-in-kubernetes\/
  .blog_detail_page
  table
  tbody
  tr:first-child
  td
  span,
main.\/blog\/pods-in-kubernetes\/
  .blog_detail_page
  table
  tbody
  tr:first-child
  td
  p {
  color: #000 !important;
}

.gradientbtn {
  display: inline-block;
  padding: 10px 25px;
  background-color: #000815;
  border-radius: 50px;
  border: 0px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  font-size: 14x;
  transition: 0.5s ease;
}
.button-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(101% + 1px);
  height: calc(102% + 1px);
  background: linear-gradient(
    90deg,
    #db00ff 0%,
    #0050d5 30.43%,
    #6700ff 68.23%,
    #db00ff 100%
  );
  border: 1px solid;

  background-size: 600% 600%;
  border-radius: 50px;
  animation: AnimateBorder 4s ease infinite;
  -webkit-animation: AnimateBorder 4s ease infinite;
  -moz-animation: AnimateBorder 4s ease infinite;
  z-index: 0;
  transform: translate(-1px, -1px);
  transition: filter 1s ease-in;
}

.button-wrapper:hover .button-bg {
  filter: blur(10px);
  transition: filter 0.4s ease-in;
}
.gradientbtn:hover {
  background-color: transparent;
}

@-webkit-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btnleftright:hover span {
  color: #000;
}
.btnleftright:hover span {
  color: #000;
}

.btnleftright::after,
.btnleftright::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
}

.btnleftright::before {
  transform: translateX(-101%);
  z-index: 1;
}

.btnleftright:hover:before {
  transform: translateX(0);
  transition: transform 350ms ease;
}

.btnleftright::after {
  z-index: 0;
  transform: translateX(101%);
  transition: none;
  transition: transform 350ms ease;
}

.btnleftright:hover:after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
  color: #000;
}

.btncolorleftright:hover span {
  color: #fff;
}
.btncolorleftright:hover span {
  color: #fff;
}

.btncolorleftright::after,
.btncolorleftright::before {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #0050d5;
  z-index: -1;
}

.btncolorleftright::before {
  transform: translateX(-101%);
  z-index: 1;
}

.btncolorleftright:hover:before {
  transform: translateX(0);
  transition: transform 350ms ease;
}

.btncolorleftright::after {
  z-index: 0;
  transform: translateX(101%);
  transition: none;
  transition: transform 350ms ease;
}

.btncolorleftright:hover:after {
  opacity: 1;
  transform: translateX(0);
  transition: transform 350ms 360ms ease;
}
.active-link {
  color: #fff;
}
.phoneNumberNobor.react-tel-input .form-control:focus {
  border: 0;
  box-shadow: none;
}
.react-tel-input .form-control:focus {
  border: 1px solid #cacaca;
  box-shadow: none;
}

@media (min-width: 1024px) {
  .container {
    max-width: 95%;
  }
}

@media (max-width: 992px) {
  .gradientbtn {
    font-size: 14px;
    padding: 8px 15px;
  }
  #innerScroll {
    max-height: 30vh;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 20px;
  }
}
