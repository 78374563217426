.location_border {border-right:1px solid #fff;}
@media screen and (min-width:1281px) {
    .location_border:nth-child(4n) {border-right: 0px;}
}
@media screen and (max-width:1280px) and (min-width:1024px) {
    .location_border:nth-child(3n) {border-right:0px;}
}
@media screen and (max-width:1023px) and (min-width:640px) {
    .location_border:nth-child(2n) {border-right:0px;}
}
@media screen and (max-width: 639px) {
    .location_border:nth-child(1n) {border-right:0px;}
}